
// XXX: Make sure the order of the routes is correct.
// The longest url under the same parent should be placed first and in decreasing order.
import i18n from '../utils/i18n'

const PRIVATE_PATH = {
  DAMAGE_OVERVIEW: 'damages',
  PRE_INSPECTION: 'pre-inspection',
  VIDEO_INSPECTION: 'video-inspection',
  ODO_METER: 'odometer',
  VEHICLE_DETAILS: 'vehicle-details',
  ACCESSORY_CHECK: 'accessories/check',
  ACCESSORIES: 'accessories',
  LOCATION: 'location',
  WELCOME: 'welcome',
  INTERIOR: 'interior',
  INTERIOR_CHECK_STEP: 'interior/:id',
  OVERVIEW: 'overview',
  EXTERIOR: 'exterior',
  EXTERIOR_CHECK_STEP: 'exterior/:id',
  PHOTO_CHECKER_SHOT: 'overview/:id',
  VIDEO_CHECKER: 'overview/video-checker',
  VIDEO_CHECKER_STEP: 'video-checker',
  TIRES_OVERVIEW: 'tires',
  SCAN_QR: 'scan-qr-code',
  SUMMARY: 'summary',
  FIND_LICENSE_PLATE: 'find-license-plate',
  ERROR: 'error',
  ROOT: '/',
}

const PUBLIC_PATH = {
  LOGIN: 'login',
  UNAUTHORIZED: 'unauthorized',
  THANK_YOU: 'thank-you',
  ERROR: 'error',
}

const ROUTES_CONFIG = [
  {
    path: '/',
    title: 'Inspect',
  },
  {
    path: PRIVATE_PATH.DAMAGE_OVERVIEW,
    title: i18n.t('damages'),
  },
  {
    path: PRIVATE_PATH.PRE_INSPECTION,
    title: '',
    footerText: i18n.t('get-started'),
    footerHref: PRIVATE_PATH.VIDEO_INSPECTION,
    footerAction: '',
  },
  {
    path: PRIVATE_PATH.WELCOME,
    title: i18n.t('landing-page-header'),
  },
  {
    path: PUBLIC_PATH.THANK_YOU,
    title: '',
    hasThankYouBackground: true,
  },
  {
    path: PRIVATE_PATH.ODO_METER,
    title: i18n.t('odometer-reading-header'),
  },
  {
    path: PRIVATE_PATH.VEHICLE_DETAILS,
    title: i18n.t('vehicle-details-header'),
  },
  {
    path: PRIVATE_PATH.ACCESSORY_CHECK,
    title: i18n.t('check-accessories-header'),
  },
  {
    path: PRIVATE_PATH.ACCESSORIES,
    title: i18n.t('check-accessories-header'),
  },
  {
    path: PUBLIC_PATH.LOGIN,
    title: i18n.t('login'),
  },
  {
    path: PUBLIC_PATH.UNAUTHORIZED,
  },
  {
    path: PRIVATE_PATH.LOCATION,
    title: i18n.t('location-header'),
  },
  {
    path: PRIVATE_PATH.VIDEO_CHECKER,
    title: i18n.t('video-checker-header'),
  },
  {
    path: PRIVATE_PATH.OVERVIEW,
    title: i18n.t('photo-checker-header'),
  },
  {
    path: PRIVATE_PATH.EXTERIOR,
    title: i18n.t('exterior'),
  },
  {
    path: PRIVATE_PATH.EXTERIOR_CHECK_STEP,
    title: i18n.t('exterior'),
  },
  {
    path: PRIVATE_PATH.INTERIOR,
    title: i18n.t('interior'),
  },
  {
    path: PRIVATE_PATH.INTERIOR_CHECK_STEP,
    title: i18n.t('interior'),
  },
  {
    path: PRIVATE_PATH.PHOTO_CHECKER_SHOT,
    title: i18n.t('photo-checker-header'),
  },
  {
    path: PRIVATE_PATH.TIRES_OVERVIEW,
    title: i18n.t('tires-overview-header'),
  },
  {
    path: PRIVATE_PATH.SCAN_QR,
    title: i18n.t('scan-qr-code-header'),
  },
  {
    path: PRIVATE_PATH.SUMMARY,
    title: i18n.t('summary'),
  },
  {
    path: PRIVATE_PATH.FIND_LICENSE_PLATE,
    title: i18n.t('find-license-plate-header'),
  },
]

export default { PRIVATE_PATH, ROUTES_CONFIG, PUBLIC_PATH }