import axios, { AxiosError, AxiosResponse } from 'axios'
import * as Sentry from '@sentry/react'
import { refreshAuth } from './apis'
import { ResultStatus } from '../constants'
import { ROUTES } from '../constants'

const REQUEST_TIME_OUT = 60000

const axiosClient = axios.create({
  withCredentials: true,
  baseURL: '/api',
  timeout: REQUEST_TIME_OUT,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  async(error: AxiosError) => {
    console.log('error', error.response)
    if (error.response?.status >= ResultStatus.ERROR) {
      window.location.replace(ROUTES.PRIVATE_PATH.ERROR)
    } else if (error.response?.status === ResultStatus.UNAUTHORIZED) {
      try {
        refreshAuth()
      } catch (refreshError) {
        Sentry.captureException(refreshError)
      }
    }
    return Promise.reject(error)
  },
)

export default axiosClient